<script>
import { Tree } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => [],
    },
    selectData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    sumbit() {
      this.$emit('success', { select: this.select, halfCheckedKeys: this.halfCheckedKeys })
    },
  },
  data() {
    return {
      halfCheckedKeys: [],
      select: this.selectData,
    }
  },
  render() {
    return (
      <Tree
        tree-data={this.typeData}
        checkable
        replaceFields={{ title: 'name', key: 'id' }}
        v-model={this.select}
        onCheck={(_, e) => {
          this.halfCheckedKeys = e.halfCheckedKeys
        }}
      ></Tree>
    )
  },
}
</script>
