<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'
import { regSourceList } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { deleteAction, getAction, postAction, putAction } from '@/command/netTool'
import { message } from 'ant-design-vue'
import RuleTree from '@/components/RuleTree'
export default {
  name: 'userManagement',
  data() {
    return {
      ...api.command.getState(),
      treeData: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList({ current = 1, pageSize = 10 } = {}) {
      api.command.getList.call(this, {
        url: '/subRole/page',
        current: current,
        size: pageSize,
      })
    },
    getHeader() {
      return [
        {
          name: '角色名称',
          key: 'roleName',
          type: 'input',
        },
        {
          name: '类型',
          key: 'sysCode',
          type: 'select',
          typeData: [
            {
              name: '全部',
              value: '',
            },
            {
              name: '平台端',
              value: 'sznc',
            },
            {
              name: 'H5商家端',
              value: 'H5Shop',
            },
          ],
        },
      ]
    },
    onOpenTree(records = {}, name) {
      getAction(`/subRole/getUserMenu?sysCode=${records.sysCode}`).then((e) => {
        let treeData = e.data
        getAction('/subRole/menuDetail', { roleCode: records.roleCode }).then((e) => {
          apiTool.showDrawer({
            title: `分配${name.replace('权限', '')}菜单权限`,
            width: 600,
            view: RuleTree,
            viewProps: {
              typeData: treeData,
              selectData: e.data,
            },
            success: ({ data, setHidden }) => {
              putAction('/subRole/menu', {
                menuIds: data.select.toString(),
                roleCode: records.roleCode,
              }).then(() => {
                message.success('关联成功')
                setHidden()
                this.getList()
              })
            },
            foot: ({ close, submit }) => {
              return [
                {
                  name: '取消',
                  onClick: close,
                },
                {
                  name: '完成',
                  type: 'primary',
                  onClick: submit,
                },
              ]
            },
          })
        })
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'roleName',
          title: '角色名称',
          align: 'left',
          customRender: (text) => {
            return (
              <div class="product-order-item-copy">
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          dataIndex: 'roleDesc',
          title: '说明',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          align: 'left',
        },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            const name = records.sysCode == 'H5Shop' ? 'H5商家权限' : '平台权限'
            return [
              {
                display: true,
                name: name,
                onClick: () => this.onOpenTree(records, name),
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.onOpenModel(records)
                },
              },
              {
                display: true,
                name: '删除',
                popTitle: '确认是否删除吗?',
                type: 'pop',
                onClick: () => {
                  deleteAction(`/subRole/${records.roleCode}`, {}, '/api').then((e) => {
                    message.success('删除成功')
                    this.getList()
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    onOpenModel(records = {}) {
      apiTool.showDrawer({
        title: records.roleCode ? '编辑角色' : '新增角色',
        width: 600,
        formData: { ...records },
        success: ({ data, setHidden }) => {
          if (records.roleCode) {
            putAction('/subRole', data).then((e) => {
              message.success('编辑成功')
              setHidden()
              this.getList()
            })
          } else {
            postAction('/subRole', data).then((e) => {
              message.success('新增成功')
              setHidden()
              this.getList()
            })
          }
          console.log('data', data)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '完成',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
        form: [
          {
            name: '角色类型',
            key: 'sysCode',
            type: 'radio',
            typeData: [
              {
                name: '平台端',
                value: 'sznc',
              },
              {
                name: 'h5商家端',
                value: 'H5Shop',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '角色名称',
            type: 'input',
            key: 'roleName',
          },
          {
            name: '说明',
            type: 'input',
            key: 'roleDesc',
          },
        ],
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          onClick: this.onOpenModel,
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onChange={this.getList}
      />
    )
  },
}
</script>
